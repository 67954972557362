export const ADD_NEW_CURRICULUM = 'ADD_NEW_CURRICULUM';

export const DELETE_CURRICULUM = 'DELETE_CURRICULUM';

export const IMPORT_CURRICULUM_DATA = 'IMPORT_CURRICULUM_DATA';

export const CHANGE_INPUT_VALUE = 'CHANGE_INPUT_VALUE';

export const UPDATE_CURICULUM = 'UPDATE_CURICULUM';

export const INDENT_CURICULUM = 'INDENT_CURICULUM';

export const OUTDENT_CURICULUM = 'OUTDENT_CURICULUM';

export const MOVE_CURICULUM = 'MOVE_CURICULUM';
